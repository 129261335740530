import type { AddressUpsert } from "@fscrypto/domain/wallet";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import type { ConnectWalletApi } from "./connect-wallet";

export const useWalletApi = (props: ConnectWalletApi) => {
  const queryClient = useQueryClient();
  const {
    data: wallet,
    isPending: isWalletPending,
    error: getWalletError,
  } = useQuery({
    queryKey: ["wallet"],
    queryFn: () => props.onGetWallet(),
  });

  const {
    mutate: addWallet,
    isPending: isAddWalletPending,
    error: addWalletError,
  } = useMutation({
    mutationFn: (input: AddressUpsert) => props.onAddWallet(input),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["wallet"] }),
  });

  const {
    mutate: removeWallet,
    isPending: isRemoveWalletPending,
    error: removeWalletError,
  } = useMutation({
    mutationFn: (id: string) => props.onDeleteWallet(id),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["wallet"] }),
  });

  return {
    wallet,
    isWalletPending,
    getWalletError,
    addWallet,
    isAddWalletPending,
    addWalletError,
    removeWallet,
    isRemoveWalletPending,
    removeWalletError,
  };
};
