import type { wallet } from "@fscrypto/domain";
import type { Chain } from "@fscrypto/domain/chain";
import type { UserWalletWithWalletAndChain } from "@fscrypto/domain/earn";
import { ArrowLeft, X } from "lucide-react";
import { useState } from "react";
import { Button } from "../../components/button/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/dialog/dialog";
import { ConnectWalletInner } from "./connect-wallet-inner";
import { ProjectAvatar } from "./network-avatar";

export type ConnectWalletApi = {
  onDeleteWallet: (id: string) => Promise<void>;
  onAddWallet: (input: wallet.AddressUpsert) => Promise<wallet.Address>;
  onGetWallet: () => Promise<UserWalletWithWalletAndChain[]>;
};

export type ConnectWalletProps = {
  onSelectWallet?: (wallet: UserWalletWithWalletAndChain) => void;
  chain?: Chain | string;
  api?: ConnectWalletApi;
  config: {
    dynamicEnvironmentId: string;
    theme?: "light" | "dark";
    myNearWalletCallbackUrl: string;
  };
  children: React.ReactNode;
};

export type WalletFlowView =
  | {
      name: "VIEW_WALLETS";
      chain?: string;
      backTo?: WalletFlowView;
    }
  | {
      name: "CHOOSE_CHAIN";
      backTo?: WalletFlowView;
    }
  | {
      name: "CONNECT_WALLET";
      chain: string;
      backTo?: WalletFlowView;
      viewWalletsTo: WalletFlowView;
    };

const getInitialViewState = (props: ConnectWalletProps) => {
  return {
    name: "VIEW_WALLETS",
    chain: typeof props.chain === "string" ? props.chain : props.chain?.slug,
  } satisfies WalletFlowView;
};

const ConnectWallet = (props: ConnectWalletProps) => {
  const [viewState, setViewState] = useState<WalletFlowView>(getInitialViewState(props));

  return (
    <Dialog
      onOpenChange={(open) => {
        // reset the view
        if (open === false) setViewState(getInitialViewState(props));
      }}
    >
      <DialogTrigger asChild>{props.children}</DialogTrigger>
      <DialogContent>
        <DialogHeader className="flex flex-row items-center justify-between space-y-0">
          {viewState.backTo && (
            <Button
              variant="ghost"
              onClick={() => {
                viewState.backTo && setViewState(viewState.backTo);
              }}
              className="size-7 p-2"
            >
              <ArrowLeft className="h-4 w-4" />
            </Button>
          )}

          <DialogTitle className="flex-1 text-center font-normal m-0">
            {viewState.name === "VIEW_WALLETS" && viewState.chain && (
              <div className="flex flex-row items-center justify-center">
                Select {articlePrefixRule(viewState.chain)} <NetworkBadge chain={viewState.chain} /> Wallet
              </div>
            )}
            {viewState.name === "VIEW_WALLETS" && !viewState.chain && <span>Your wallets</span>}
            {viewState.name === "CHOOSE_CHAIN" && <span>Select a network</span>}
            {viewState.name === "CONNECT_WALLET" && (
              <div className="flex flex-row items-center justify-center">
                Connect {articlePrefixRule(viewState.chain)} <NetworkBadge chain={viewState.chain} /> Wallet
              </div>
            )}
          </DialogTitle>

          <DialogClose asChild>
            <Button variant="ghost" className="size-7 p-2">
              <X className="size-4" />
            </Button>
          </DialogClose>
        </DialogHeader>
        <ConnectWalletInner {...props} viewState={viewState} setViewState={setViewState} />
      </DialogContent>
    </Dialog>
  );
};

const articlePrefixRule = (nextWord: string) => {
  return ["A", "E", "I", "O", "U"].includes(nextWord[0].toUpperCase()) ? "an" : "a";
};

const NetworkBadge = (props: { chain: Chain | string }) => {
  const chain = typeof props.chain === "string" ? props.chain : props.chain.slug;
  return (
    <div className="bg-bg-secondary rounded-xl pl-1 pr-2 py-1 mx-1 flex flex-row items-center border-bg-primary border-2">
      <ProjectAvatar project={chain} className="ml-1 mr-2" />
      <span className="text-text-primary">{chain.charAt(0).toUpperCase() + chain.slice(1)}</span>
    </div>
  );
};

// default export so we can lazy load the component
export default ConnectWallet;
