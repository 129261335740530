import type { wallet } from "@fscrypto/domain";
import { Suspense, lazy, useMemo } from "react";

import { ScrollArea } from "../../components/scroll-area/scroll-area";
import { Skeleton } from "../../components/skeleton/skeleton";

// Lazy load these to avoid SSR issues (like "self is not defined" on the server side)

const Dynamic = lazy(() => import("@fscrypto/web3-connectors/dynamic"));
const AptosWalletConnectors = lazy(() => import("@fscrypto/web3-connectors/aptos"));
const SeiWalletConnectors = lazy(() => import("@fscrypto/web3-connectors/sei"));
const NearWalletConnectors = lazy(() => import("@fscrypto/web3-connectors/near"));
const StellarWalletConnectors = lazy(() => import("@fscrypto/web3-connectors/stellar"));
const SuiWalletConnectors = lazy(() => import("@fscrypto/web3-connectors/sui"));

const DYNAMIC_NETWORKS = [
  "ethereum",
  "avalanche",
  "polygon",
  "flow",
  "solana",
  "axelar",
  "blast",
  "kaia",
  "gnosis",
  "sei-evm",
  "arbitrum",
  "flow-evm",
  "ink",
  "swell",
  "ronin",
  "boba",
  "bob",
] as const;
const CUSTOM_NETWORKS = ["aptos", "near", "sei", "stellar", "sui"] as const;

export type SupportedNetwork = (typeof DYNAMIC_NETWORKS)[number] | (typeof CUSTOM_NETWORKS)[number];
export const SUPPORTED_NETWORKS = [...DYNAMIC_NETWORKS, ...CUSTOM_NETWORKS].sort((a, b) =>
  a.localeCompare(b),
) as readonly SupportedNetwork[];

const Web3Connectors = ({
  network,
  onSuccess,
  config,
}: {
  network: SupportedNetwork;
  onSuccess: (address: wallet.Web3Connector) => void;
  config: {
    myNearWalletCallbackUrl: string;
    dynamicEnvironmentId: string;
    theme: "light" | "dark";
  };
}) => {
  const networkCanUseDynamic = useMemo(() => {
    return !!(network && DYNAMIC_NETWORKS.find((n) => n === network));
  }, [network]);

  if (networkCanUseDynamic) {
    return (
      <Suspense fallback={<LoadingSkeleton />}>
        <Dynamic
          theme={config?.theme ?? "light"}
          environmentId={config.dynamicEnvironmentId}
          network={network}
          onSuccess={onSuccess}
        />
      </Suspense>
    );
  }

  if (network === "aptos")
    return (
      <Suspense fallback={<LoadingSkeleton />}>
        <ScrollArea className="h-[350px]">
          <AptosWalletConnectors onSuccess={onSuccess} />
        </ScrollArea>
      </Suspense>
    );
  if (network === "near")
    return (
      <Suspense fallback={<LoadingSkeleton />}>
        <ScrollArea className="mt-4 space-y-2 h-[350px]">
          <NearWalletConnectors myNearWalletCallbackUrl={config.myNearWalletCallbackUrl} onSuccess={onSuccess} />
        </ScrollArea>
      </Suspense>
    );
  if (network === "sei")
    return (
      <Suspense fallback={<LoadingSkeleton />}>
        <ScrollArea className="mt-4 space-y-2 h-[350px]">
          <SeiWalletConnectors onSuccess={onSuccess} />
        </ScrollArea>
      </Suspense>
    );

  if (network === "stellar")
    return (
      <Suspense fallback={<LoadingSkeleton />}>
        <ScrollArea className="mt-4 space-y-2 h-[350px]">
          <StellarWalletConnectors onSuccess={onSuccess} />
        </ScrollArea>
      </Suspense>
    );

  if (network === "sui")
    return (
      <Suspense fallback={<LoadingSkeleton />}>
        <ScrollArea className="mt-4 space-y-2 h-[350px]">
          <SuiWalletConnectors onSuccess={onSuccess} />
        </ScrollArea>
      </Suspense>
    );

  console.error("Unsupported network", network);
  return null;
};

const LoadingSkeleton = () => {
  return (
    <div className="space-y-4 my-2">
      <Skeleton className="h-14 w-full" />
      <Skeleton className="h-14 w-full" />
      <Skeleton className="h-14 w-full" />
    </div>
  );
};

export default Web3Connectors;
